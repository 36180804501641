import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isCordovaPluginAvailable } from './cordova-plugin-check';

export const hapticFeedback = async (weight: ImpactStyle = ImpactStyle.Light): Promise<void> => {
  /**
   * To support older version of the app, we'd check beforehand
   * if the plugin is loaded on the native side. Otherwise this
   * function will just be a 0x90.
   **/
  if (!isCordovaPluginAvailable('Haptics')) {
    return;
  }

  // we add a trivial exception handler because this method always throws in Safari:
  Haptics.impact({ style: weight }).catch((_) => _);
};
