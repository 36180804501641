import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import type { IWedding } from '@bridebook/models/source/models/Weddings.types';
import type { IUISupplier } from '@bridebook/toolbox/src/types';

const BUDGET_RANGE = [0.5, 1.05];
const BUDGET_LIMITS = [1000, 99000];

export function isBudgetMatch(
  budget: IWedding['budget'],
  supplier:
    | Pick<ISupplier, 'type' | 'targeting'>
    | Pick<IUISupplier, 'type' | 'coupleBudgetMin'>
    | null = null,
) {
  let threshold = 0;

  /**
   * Budget match is a feature only for venues.
   */
  if (supplier?.type?.[0] === 'venue') {
    threshold = (supplier as unknown as ISupplier).targeting?.budget?.[0] ?? 0;
  } else if (supplier?.type === 'venue') {
    threshold = Number((supplier as IUISupplier).coupleBudgetMin) ?? 0;
  }

  if (budget == null || threshold === 0) {
    return false;
  }

  /**
   * Budget match logic from LIVE-9688.
   */
  return (
    budget >= BUDGET_LIMITS[0] &&
    budget <= BUDGET_LIMITS[1] &&
    threshold >= budget * BUDGET_RANGE[0] &&
    threshold <= budget * BUDGET_RANGE[1]
  );
}
